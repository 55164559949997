const smallWidth = 15
const mediumWidth = 20
const largeWidth = 30
const xLargeWidth = 60

const exportConfig = {
  worksheets: [
    {
      id: '1',
      name: 'параметры расчёта',
      columns: [
        {
          header: 'Код стандарта',
          key: 'std_id',
          width: mediumWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Наименование стандарта',
          key: 'caption',
          width: xLargeWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'left',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Приказ МФ РФ',
          key: 'order',
          width: largeWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Количество пациентов',
          key: 'patient',
          width: mediumWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
      ],
      firstRow: {
        font: {
          name: 'Calibri',
          family: 4,
          size: 10,
          bold: true,
          color: { argb: '222222' },
        },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        height: 24,
      },
      additionalRows: {
        id: 'mnn',
        columns: [
          {
            header: '№ п.п.',
            key: 'id',
            fill: {
              fgColor: { argb: 'd6f0b4' },
              pattern: 'solid',
              type: 'pattern',
            },
          },
          {
            header: 'Наименование лекарственного препарата (МНН)',
            key: 'caption',
            fill: {
              fgColor: { argb: 'd6f0b4' },
              pattern: 'solid',
              type: 'pattern',
            },
          },
        ],
        firstRow: {
          font: {
            name: 'Calibri',
            family: 4,
            size: 10,
            bold: true,
            color: { argb: '222222' },
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          },
          height: 24,
        },
      },
    },
    {
      id: '2',
      name: 'расчёт',
      columns: [
        {
          header: '№ п.п.',
          key: 'id',
          width: 5,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'Код АТХ',
          key: 'atc',
          width: mediumWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'АТХ',
          key: 'atc_name',
          width: xLargeWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'left',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'МНН',
          key: 'mnn',
          width: largeWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'left',
            },
          },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Форма выпуска',
          key: 'form',
          width: mediumWidth,
          style: {
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Расчётная потребность',
          key: 'res',
          width: mediumWidth,
          style: { alignment: { wrapText: true, horizontal: 'right' } },
          fill: {
            fgColor: { argb: 'f8aaaa' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Единица измерения',
          key: 'unit',
          width: smallWidth,
          style: { alignment: { wrapText: true, horizontal: 'right' } },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
      ],
      firstRow: {
        font: {
          name: 'Calibri',
          family: 4,
          size: 10,
          bold: true,
          color: { argb: '222222' },
        },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        height: 32,
      },
      views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
      autoFilter: { from: 'B1', to: 'G1' },
    },
  ],
  // views: [{ state: 'frozen', xSplit: 0, ySplit: 3, topLeftCell: 'F10', activeCell: '' }],
}

export default exportConfig
