import { createNamespacedHelpers } from 'vuex'
import { registerModule, unregisterModule } from '@/store'
import { requirementModule } from './const'

import requirement from './requirementCalc'

export default {
  name: requirementModule,
  register: () =>
    registerModule(requirementModule, { ...requirement, namespaced: true }),
  unregister: () => unregisterModule(requirementModule),
  ...createNamespacedHelpers(requirementModule),
}
