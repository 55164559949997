<template>
  <v-container fluid v-if="showTable">
    <v-row dense class="mt-2" id="result-page">
      <v-col md="12">
        <v-divider class="mb-2" />
        <div class="text-h6">
          4. Вывод результатов расчёта потребностей по выбранным стандартам:
        </div>
      </v-col>
    </v-row>
    <v-row class="my-0" dense>
      <v-col class="text-right">
        <DownloadResourceRQ :resData="reqCalcRequirement" :options="options" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer />
      <v-col cols="12" sm="7" md="6" offset-md="3" class="pt-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск по результатам"
          single-line
          clearable
          hide-details
          :disabled="loading || !results?.length"
        />
      </v-col>
    </v-row>
    <v-data-table
      id="result-table"
      :items="results"
      :headers="headers"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      :show-expand="isAdmin && showExpand"
      :hide-default-footer="hideFooter"
      :footerProps="table_footer_options"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      item-key="id"
      no-results-text="Ничего не нашлось"
      loading-text="Список результатов расчёта загружается..."
    >
      <template v-slot:[`item.id`]="{ index }">
        <span>{{ index + 1 }}.</span>
      </template>
      <template v-slot:[`item.atc`]="{ value }">
        <span :inner-html.prop="value | highlight(search)" />
      </template>
      <template v-slot:[`item.mnn`]="{ value }">
        <span :inner-html.prop="value | highlight(search)" />
      </template>
      <template v-slot:[`item.atc_name`]="{ value }">
        <span :inner-html.prop="value | highlight(search)" />
      </template>
      <template v-slot:[`item.res`]="{ item, value }">
        <span> {{ value | smartRoundVal }} {{ item?.unit || '' }} </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }" v-if="isAdmin">
        <td :colspan="headers.length">
          <DetailsLPItems :item="item" />
        </td>
      </template>
    </v-data-table>
    <v-row v-if="emptyDivHeight">
      <v-col>
        <div :style="`height: ${emptyDivHeight}px`">
          <!-- empty div -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/store'
import requirementModule from '../../store'
import { smartRound, ntl } from '@/lib/strUtils'
import DetailsLPItems from './DetailsLPItems'
import DownloadResourceRQ from './DownloadResourceRQ'

export default {
  name: 'CalcResults',
  components: { DetailsLPItems, DownloadResourceRQ },
  props: {
    options: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  data() {
    return {
      // "atc": "A08A",
      // "mnn": "Метформин+Сибутрамин",
      // "unit": "шт.",
      // "form": "850 мг+15 мг",
      // "atc_name": "Комбинированные препараты пероральных гипогликемических средств",
      // "res": 8928.0576
      loading: false,
      headers: [
        {
          text: '№',
          value: 'id',
          width: '5px',
          cellClass: 'text--disabled',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Код АТХ',
          value: 'atc',
          width: '100px',
          cellClass: 'font-weight-medium',
        },

        { text: 'АТХ', value: 'atc_name' },
        { text: 'МНН', value: 'mnn', width: '200px' },
        // { text: 'Форма', value: 'form' },
        { text: 'Потребность', value: 'res', align: 'end', width: '200px' },
        { text: '', value: 'data-table-expand', sortable: false },
      ],
      search: '',
      expanded: [],
      singleExpand: true,
      showExpand: true,
      table_footer_options: {
        itemsPerPageOptions: [25, 50, 100, 250, -1],
      },
      page: 1,
      itemsPerPage: 25,
      goToOptions: {
        duration: 1000,
        offset: 0,
        easing: 'easeInOutCubic',
      },
      emptyDivHeight: 0,
    }
  },
  filters: {
    smartRoundVal(value) {
      return typeof value === 'number' ? ntl(smartRound(value)) : value
    },
  },
  watch: {
    options: 'getResult',
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...requirementModule.mapGetters(['reqCalcRequirement']),
    results() {
      return this.reqCalcRequirement?.result?.length
        ? this.reqCalcRequirement.result.map((item, index) => ({
            id: index,
            ...item,
          }))
        : []
    },
    showTable() {
      return Boolean(this.options?.std?.length)
    },
    resultsLoading() {
      return this.loadingReqCalcRequirement !== STATUS.IDLE
    },
    hideFooter() {
      return this.results?.length <= 25
    },
  },
  methods: {
    ...requirementModule.mapActions(['GET_STD_REQUIREMENT_CALC']),
    async getResult() {
      if (!this.options?.std?.length || !this.resultsLoading) return

      this.loading = true
      try {
        await this.GET_STD_REQUIREMENT_CALC({
          std: this.options.std,
          ...(this.options.mnn && { mnn: this.options.mnn }),
        })
      } finally {
        this.loading = false
        this.scrollTo()
      }
    },

    scrollTo() {
      this.$nextTick(() => {
        const tableEl = document.getElementById('result-table')
        if (tableEl) {
          const emptyDivHeight =
            this.$vuetify.breakpoint.height - 220 - tableEl.clientHeight
          this.emptyDivHeight = emptyDivHeight > 0 ? emptyDivHeight : 0

          this.$vuetify.goTo('#result-page', this.goToOptions)
        }
      })
    },
  },
}
</script>
