<template>
  <div>
    <v-simple-table v-if="item?.items?.length" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">МНН</th>
            <th class="text-right">Количество пациентов</th>
            <th class="text-right">Потребность</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(u, index) in item.items"
            :key="`${u.atc}-${index}-${u.res}`"
          >
            <td>{{ u?.mnn || '' }}</td>
            <td class="text-right">{{ u?.patient || '' }}</td>
            <td class="text-right">
              {{ u?.res ? smartRoundVal(u.res) : '' }} {{ u?.unit || '' }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <span v-else> Нет данных </span>
  </div>
</template>

<script>
import { smartRound, ntl } from '@/lib/strUtils'

export default {
  name: 'DetailsLPItems',
  props: {
    item: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  methods: {
    smartRoundVal(value) {
      return typeof value === 'number' ? ntl(smartRound(value)) : value
    },
  },
}
</script>
