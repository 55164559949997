<template id="rate-pacients">
  <!-- <v-layout v-if="standarts?.length"> -->
  <v-container fluid class="mb-2" v-if="standarts?.length">
    <v-row dense class="mt-2">
      <v-col md="12">
        <v-divider class="mb-2" />
        <div class="text-h6">
          3. Введите количество активно лечащихся пациентов для выбранных
          стандартов:
        </div>
      </v-col>
    </v-row>
    <v-row class="pb-2">
      <v-col cols="7" offset="1">
        <div class="text-subtitle-2">Стандарт:</div>
      </v-col>
      <v-col cols="2">
        <div class="text-subtitle-2 text-center">Приказ МЗ РФ:</div>
      </v-col>
      <v-col cols="2">
        <div class="text-subtitle-2 text-right">Количество:</div>
      </v-col>
    </v-row>
    <v-row
      v-for="(standart, index) in standarts"
      :key="index"
      class="custom-row"
    >
      <v-col cols="1" class="text-center mt-1">
        <!-- <v-checkbox v-model="selected" :value="standart.std_id" hide-details /> -->
        <v-simple-checkbox
          v-model="selected[standart.std_id]"
          color="primary"
        />
      </v-col>
      <v-col cols="7" :class="{ 'text--disabled': !selected[standart.std_id] }">
        <div class="v-label font-weight-medium">{{ standart.std_id }}</div>
        <div class="text-subtitle-2 font-weight-regular">
          {{ standart.caption }}
        </div>
      </v-col>
      <v-col
        cols="2"
        align="center"
        class="pt-2"
        :class="{ 'text--disabled': !selected[standart.std_id] }"
      >
        <div class="text-subtitle-2">№ {{ standart.order_num }}</div>
        <div class="text-subtitle-2 font-weight-regular">
          {{ standart.order_date }}
        </div>
      </v-col>
      <v-col cols="2" :key="index">
        <v-text-field
          v-model.number="pacientCounts[standart.std_id]"
          :disabled="!selected[standart.std_id]"
          :rules="numberRules"
          reverse
          required
          dense
          type="number"
          @change="onChange"
          @input="onChange"
          @blur="onChange"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-2" align="center" justify="center">
      <v-col cols="12">
        <v-layout align-center justify-center>
          <v-btn
            :disabled="!isValid"
            :loading="resultsLoading"
            outlined
            color="primary"
            @click="onCalculate"
            >Расчитать</v-btn
          >
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
  <!-- </v-layout> -->
</template>

<script>
import { STATUS } from '@/store'
import requirementModule from '../../store'
export default {
  name: 'RatePacients',
  props: {
    standarts: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      pacientCounts: {},
      selected: {},
      numberRules: [
        v => String(v).length > 0 || 'Поле не должно быть пустым',
        v => Number.isInteger(Number(v)) || 'Значение должно быть целым числом',
        v => v > 0 || 'Число должно быть больше нуля',
      ],
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    standarts: 'init',
  },
  computed: {
    ...requirementModule.mapGetters(['loadingReqCalcRequirement']),
    isValid() {
      if (
        !Object.keys(this.pacientCounts).length ||
        !Object.keys(this.selected).length
      )
        return false

      let r = true
      for (const key in this.selected) {
        if (this.selected[key]) {
          r = false
          break
        }
      }

      if (r) return

      for (const key in this.selected) {
        if (
          this.selected[key] &&
          (this.pacientCounts[key] <= 0 ||
            typeof this.pacientCounts[key] !== 'number')
        )
          return false
      }
      return true
    },
    resultsLoading() {
      return this.loadingReqCalcRequirement !== STATUS.IDLE
    },
  },
  methods: {
    init() {
      // если уже колличество пациентов вводилось - не перетрёт на 1000.
      this.selected = {}
      this.standarts.forEach(standart => {
        if (!this.pacientCounts?.[standart.std_id]) {
          this.$set(this.pacientCounts, standart.std_id, 1000)
        }
        if (
          !this.selected?.[standart.std_id] ||
          this.selected[standart.std_id] === undefined
        ) {
          // this.selected[standart.std_id] = true
          this.$set(this.selected, standart.std_id, true)
        }
      })
    },
    onChange() {
      this.$emit('validate', this.pacientCounts)
    },
    onCalculate() {
      if (this.isValid) {
        const p = []
        for (const key in this.selected) {
          if (this.selected[key] && this.pacientCounts[key]) {
            const standart = this.standarts.find(s => s.std_id == key) || {}
            const { caption = '', order_date = '', order_num = '' } = standart
            p.push({
              std_id: key,
              patient: this.pacientCounts[key],
              caption,
              order_date,
              order_num,
            })
          }
        }
        if (p.length) {
          this.$emit('onCalculate', p)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
// .custom-row {
// max-height: 72px;
// }
</style>
