import api from '../api'
// import Vue from 'vue'
import { STATUS } from '@/store'

export class EApiRequirementDataException extends Error {
  constructor(message) {
    super(`[requirementCalc] ${message}`)
    this.name = 'EApiRequirementDataException'
  }
  static raiseIfLoading(loadingState, title = 'Расчёт потребностей') {
    if (loadingState !== STATUS.IDLE)
      throw new this(`${title} находится на стадии - ${loadingState}`)
  }
}

const state = {
  loadingReqCatalog: STATUS.IDLE,
  loadingReqAge: STATUS.IDLE,
  loadingReqMNN: STATUS.IDLE,
  loadingReqMedicine: STATUS.IDLE,
  loadingReqCalcRequirement: STATUS.IDLE,

  reqCatalog: [],
  reqAge: [],
  reqMNN: [],
  reqMedicine: [],
  reqCalcRequirement: {},
}

const getters = {
  loadingReqCatalog: state => state.loadingReqCatalog,
  loadingReqAge: state => state.loadingReqAge,
  loadingReqMNN: state => state.loadingReqMNN,
  loadingReqMedicine: state => state.loadingReqMedicine,
  loadingReqCalcRequirement: state => state.loadingReqCalcRequirement,

  reqCatalog: state => state.reqCatalog,
  reqAge: state => state.reqAge,
  reqMNN: state => state.reqMNN,
  reqMedicine: state => state.reqMedicine,
  reqCalcRequirement: state => state.reqCalcRequirement,

  // Пользователь может производить расчёт потребностей
  canReqCalcStandarts: (state, getters, rootState, rootGetters) =>
    rootGetters.isAdmin || rootGetters.userAttributes?.std?.canReqCalc || false,
}

const mutations = {
  SET_DEFAULT_STD_REQUIREMENT(state) {
    state.reqCatalog = []
    state.reqAge = []
    state.reqMNN = []
    state.reqMedicine = []
    state.reqCalcRequirement = {}

    state.loadingReqCatalog = STATUS.IDLE
    state.loadingReqAge = STATUS.IDLE
    state.loadingReqMNN = STATUS.IDLE
    state.loadingReqMedicine = STATUS.IDLE
    state.loadingReqCalcRequirement = STATUS.IDLE
  },

  SET_LOADING_STD_REQUIREMENT_CATALOG(state, st = STATUS.IDLE) {
    state.loadingReqCatalog = st
  },

  SET_LOADING_STD_REQUIREMENT_AGE(state, st = STATUS.IDLE) {
    state.loadingReqAge = st
  },

  SET_LOADING_STD_REQUIREMENT_MNN(state, st = STATUS.IDLE) {
    state.loadingReqMNN = st
  },

  SET_LOADING_STD_REQUIREMENT_MEDICINE(state, st = STATUS.IDLE) {
    state.loadingReqMedicine = st
  },

  SET_LOADING_STD_REQUIREMENT_CALC(state, st = STATUS.IDLE) {
    state.loadingReqCalcRequirement = st
  },

  SET_STD_REQUIREMENT_CATALOG(state, data = []) {
    state.reqCatalog = data
  },

  SET_STD_REQUIREMENT_AGE(state, data = []) {
    if (data?.length) {
      const index = data.indexOf('взрослые, дети')
      if (index > -1) {
        data.splice(index, 1)
      }
    }
    state.reqAge = data
  },

  SET_STD_REQUIREMENT_MNN(state, data = []) {
    state.reqMNN = data
  },

  SET_STD_REQUIREMENT_MEDICINE(state, data = []) {
    state.reqMedicine = data
  },

  SET_STD_REQUIREMENT_CALC(state, data = {}) {
    state.reqCalcRequirement = data
  },
}

const actions = {
  // загрузить словарь возрастов
  async GET_STD_REQUIREMENT_AGE({ commit, getters }) {
    // уходим если уже загружены
    // в первом элементе пустая строка
    if (getters.reqAge?.length > 1) return

    EApiRequirementDataException.raiseIfLoading(
      getters.loadingReqAge,
      'Каталог возрастов'
    )
    try {
      commit('SET_LOADING_STD_REQUIREMENT_AGE', STATUS.LOADING)
      commit('SET_STD_REQUIREMENT_AGE', [])

      const data = await api.getStdAgeCat()

      commit('SET_STD_REQUIREMENT_AGE', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STD_REQUIREMENT_AGE',
          text: `Ошибка получения списка доступных возрастов`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STD_REQUIREMENT_AGE', STATUS.IDLE)
    }
  },

  // загрузить MNN через список ИД стандартов
  async GET_STD_REQUIREMENT_MNN({ commit, getters }, ids = []) {
    EApiRequirementDataException.raiseIfLoading(
      getters.loadingReqMNN,
      'Список MNN'
    )
    try {
      commit('SET_LOADING_STD_REQUIREMENT_MNN', STATUS.LOADING)
      commit('SET_STD_REQUIREMENT_MNN', [])

      const data = await api.getStdMNNCat({ std_id: ids })

      commit('SET_STD_REQUIREMENT_MNN', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_LOADING_STD_REQUIREMENT_MNN',
          text: `Ошибка получения списка MNN`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STD_REQUIREMENT_MNN', STATUS.IDLE)
    }
  },

  // загрузить MEDICINE через список ИД стандартов и/или список MNN
  async GET_STD_REQUIREMENT_MEDICINE({ commit, getters }, options = {}) {
    EApiRequirementDataException.raiseIfLoading(
      getters.loadingReqMNN,
      'Список MEDICINE'
    )
    try {
      commit('SET_LOADING_STD_REQUIREMENT_MEDICINE', STATUS.LOADING)
      commit('SET_STD_REQUIREMENT_MEDICINE', [])

      const data = await api.getStdMedicine(options)

      commit('SET_STD_REQUIREMENT_MEDICINE', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STD_REQUIREMENT_MEDICINE',
          text: `Ошибка получения списка MEDICINE`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STD_REQUIREMENT_MEDICINE', STATUS.IDLE)
    }
  },

  // загрузить Каталог Утверждённых Стандартов
  // через список MNN и/или строку перечисление кодов МКБ,
  // с возможностью уточнения по возрасту
  async GET_STD_REQUIREMENT_CATALOG({ commit, getters }, options = {}) {
    EApiRequirementDataException.raiseIfLoading(
      getters.loadingReqMNN,
      'Катлог утверждённых стандартов'
    )
    try {
      commit('SET_LOADING_STD_REQUIREMENT_CATALOG', STATUS.LOADING)
      if (!options?.saved) {
        commit('SET_STD_REQUIREMENT_CATALOG', [])
      }
      const data = await api.getStdCatalog(options)

      commit('SET_STD_REQUIREMENT_CATALOG', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STD_REQUIREMENT_CATALOG',
          text: `Ошибка получения списка Катлога утверждённых стандартов`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STD_REQUIREMENT_CATALOG', STATUS.IDLE)
    }
  },

  // Расчёт потребностей
  async GET_STD_REQUIREMENT_CALC({ commit, getters }, options) {
    EApiRequirementDataException.raiseIfLoading(
      getters.loadingReqMNN,
      'Расчёт потребностей'
    )
    try {
      commit('SET_LOADING_STD_REQUIREMENT_CALC', STATUS.LOADING)
      commit('SET_STD_REQUIREMENT_CALC', {})

      const data = await api.getStdCaclRecuirement(options)

      commit('SET_STD_REQUIREMENT_CALC', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_STD_REQUIREMENT_CALC',
          text: `Ошибка получения расчёта потребностей`,
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_LOADING_STD_REQUIREMENT_CALC', STATUS.IDLE)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
