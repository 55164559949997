<template>
  <v-flex>
    <span>
      <v-menu bottom left open-on-hover offset-y rounded="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            title="Выгрузить результаты расчёта потребностей"
            text
            small
            class="mr-0 pr-2"
            :disabled="loading"
            :loading="loading"
          >
            Выгрузить
            <v-icon right>mdi-file-export-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item link v-if="isAdmin" @click="downloadJson">
            <v-list-item-icon>
              <v-icon color="orange">mdi-code-json</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              title="Выгрузить расчёт в формате json"
              class="text-left"
            >
              Файл jSON
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="exportExcel">
            <v-list-item-icon>
              <v-icon color="success">mdi-file-excel-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              title="Выгрузить расчёт в файл Excel"
              class="text-left"
            >
              Расчёт потребностей (.xlsx)
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </v-flex>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { dateStamp } from '@/lib/date'
import { smartRound, ntl } from '@/lib/strUtils'
import ExportToExcel from '@/services/export/excel/excel'
import exportConfig from '../../config/requirementToExcel'

export default {
  name: 'DownloadResourceRQ',
  data: () => ({
    loadingJson: false,
    loadingExcel: false,
  }),
  props: {
    resData: {
      type: Object,
      required: false,
      default: () => {},
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    loading() {
      return this.loadingExcel || this.loadingJson
    },
  },
  methods: {
    ...mapMutations(['SET_ERROR']),
    async downloadJson() {
      this.loadingJson = true
      let element = document.createElement('a')
      const fileName = `requirement_data_${dateStamp()}.json`
      try {
        let text = JSON.stringify(this.resData)

        element.setAttribute(
          'href',
          'data:application/json;charset=utf-8,' + encodeURIComponent(text)
        )
        element.setAttribute('download', fileName)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()
      } catch (e) {
        this.SET_ERROR({
          head: 'Экспорт данных в Json',
          text: fileName,
          e,
        })
      } finally {
        this.loadingJson = false
        document.body.removeChild(element)
      }
    },
    async exportExcel() {
      this.loadingExcel = true

      const fileName = `Расчёт потребностей_${dateStamp()}.xlsx`

      try {
        const rows = []
        const { std = [], mnn = [] } = this.options || {}

        const stds = std.map(s => {
          const { order_num = '', order_date = '', ...rest } = s

          return { ...rest, order: `№ ${order_num} от ${order_date}` }
        })

        // Первый Лист ИД 1 (параметры рачёта)
        rows.push({ id: '1', data: stds })
        rows.push({ id: '2', data: this.convertData(this.resData) })

        if (mnn.length) {
          // const mnns = mnn.map((m, index) => ({ id: index + 1, caption: m }))
          const mnns = mnn.map((m, index) => [index + 1, m])
          rows.push({ id: 'mnn', data: mnns })
        }

        const options = {
          ...exportConfig,
          fileName,
        }

        // Экспорт
        await ExportToExcel.downloadXLSX({ rows, options })
      } catch (e) {
        this.SET_ERROR({
          head: 'Экспорт данных в Excel',
          text: fileName,
          e,
        })
      } finally {
        this.loadingExcel = false
      }
    },

    convertData(data) {
      const list = data?.result?.length
        ? data.result.map((item, index) => {
            const { res, ...rest } = item
            return {
              id: index + 1,
              // res: this.smartRoundVal(res),
              res,
              ...rest,
            }
          })
        : []

      return list
    },
    smartRoundVal(value) {
      return typeof value === 'number' ? ntl(smartRound(value)) : value
    },
  },
}
</script>
