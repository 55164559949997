<template>
  <v-container id="requirement-page">
    <!-- <template v-if="canReqCalcStandarts"> -->
    <v-row dense class="mb-2">
      <v-col md="10">
        <div class="text-h6">
          <!-- <v-chip color="green" outlined text-color="grey">1</v-chip> -->
          1. Укажите условия выбора стандарта:
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer />
      <v-col cols="12" class="text-right mb-2">
        <v-combobox
          v-model="ageCategory"
          :items="reqAge"
          :disabled="loading"
          class="ml-6"
          dense
          multiple
          clearable
          label="Возрастная группа"
          @change="onChange()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer />
      <v-col cols="12" class="text-right mb-2">
        <v-combobox
          v-model.trim="mnn"
          :items="reqMNN"
          :menu-props="{ maxHeight: '400' }"
          :disabled="loading"
          class="ml-6"
          dense
          multiple
          clearable
          label="МНН"
          hint="МНН выбор"
          @change="onChange()"
          @blur="onChange()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer />
      <v-col cols="12" class="text-right mb-2">
        <v-textarea
          v-model.trim="mkb10"
          class="ml-6"
          dense
          readonly
          clearable
          no-resize
          auto-grow
          rows="1"
          append-outer-icon="mdi-store-search-outline"
          label="Коды МКБ-10 из Справочника"
          @click:append-outer="mkbTree = true"
          @input="onChange()"
          @blur="onChange()"
        />
      </v-col>
    </v-row>
    <v-row dense class="mb-2">
      <v-col md="10">
        <div class="text-h6">
          <!-- {{ title }} -->
          2. Выберете утверждённые стандарты для расчёта:
        </div>
      </v-col>
    </v-row>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      fixed
      bottom
      color="deep-purple accent-4"
    ></v-progress-linear>
    <v-row>
      <v-col>
        <v-treeview
          v-if="catalog.length"
          v-model="selection"
          :search="searchText"
          :filter="someSearch"
          :items="catalog"
          selectable
          selected-color="blue"
          hoverable
          return-object
          dense
        >
          <!-- :open.sync="openedItems" -->
          <template v-slot:[`append`]="{ item }">
            <div class="text-right text--disabled" v-if="isAdmin">
              {{ item.id }}
            </div>
          </template>
          <template v-slot:[`label`]="{ item }">
            <!-- Уровень Root -->
            <span
              v-if="item?.root"
              class="text-subtitle-1 font-weight-bold root-level"
            >
              {{ item.caption }}</span
            >
            <!-- Уровень ClinRec -->
            <span class="text-subtitle-1" v-else-if="item?.cr_id">
              <span
                class="mr-4 text--secondary lvl-clinrecs"
                :inner-html.prop="item.cr_id | highlight(searchText)"
              />
              <span
                class="font-weight-medium"
                :inner-html.prop="item.caption | highlight(searchText)"
              />
            </span>
            <!-- Уровень Standarts -->
            <span v-else class="text-body-2">
              <div>
                <span
                  class="mr-4 text--secondary lvl-standarts"
                  :inner-html.prop="item.std_id | highlight(searchText)"
                />
                <span
                  class="text-subtitle-2 font-weight-regular lvl-standarts-caption"
                  :inner-html.prop="item.caption | highlight(searchText)"
                  :title="item.caption"
                />
              </div>
              <div class="mb-1">
                <span class="mr-4 text--secondary lvl-standarts">
                  Диагноз:</span
                >
                <!-- :inner-html.prop="item.age_cat | highlight(searchText)" -->
                <span
                  class="text-subtitle-2 font-weight-regular lvl-standarts-caption"
                  :inner-html.prop="item.diag | highlight(searchText)"
                />
              </div>
            </span>
          </template>
        </v-treeview>
        <div class="text-subtitle-1" v-else>
          Отсутствуют Стандарты соответсвующие выбранным критериям
        </div>
      </v-col>
    </v-row>

    <!-- Вывод выбранных стандартов и ввод количества пациентов -->
    <v-row>
      <v-col>
        <RatePacients :standarts="selection" @onCalculate="onCalculate" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <CalcResults :options="calcOptions" />
      </v-col>
    </v-row>

    <!-- справочник мкб-10 -->
    <MkbTreeSelectDialog
      v-if="mkbTree"
      :codes-string="mkb10"
      @close-mkb-tree="mkbTree = false"
      @confirm-selection="confirmCodes"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { ignoreOrderCompare } from '@/lib/objects.js'
import requirementModule from '../store'
import MkbTreeSelectDialog from './dialog/MkbTreeSelectDialog'
import RatePacients from './components/RatePacients'
import CalcResults from './components/CalcResults'

export default {
  name: 'RequirementPage',
  components: {
    MkbTreeSelectDialog,
    RatePacients,
    CalcResults,
  },
  data() {
    return {
      loading: false,
      error_message: '',
      title: '',
      mkbTree: false,
      ageCategory: [],
      mnn: '',
      mkb10: '',
      oldAgeCategory: [],
      oldMNN: '',
      oldMKB10: '',
      searchText: '',
      selection: [],
      calcOptions: {},
    }
  },
  watch: {
    selection: 'resetCalcOptions',
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...requirementModule.mapGetters([
      'reqAge',
      'reqMNN',
      'reqCatalog',
      'canReqCalcStandarts',
    ]),

    catalog() {
      if (!this.reqCatalog.catalog?.length) return []
      const reqCatalog = []
      this.reqCatalog.catalog.forEach(rootElement => {
        const { id, caption, clinrecs = [] } = rootElement

        const children = []
        clinrecs.forEach(clinrecElement => {
          const { id, standarts = [], ...rest } = clinrecElement

          children.push({
            id: 'l2-' + id,
            ...rest,
            children: standarts.map(el => ({
              ...el,
              children: [],
            })),
          })
        })
        reqCatalog.push({ id: 'l1-' + id, caption, children, root: true })
      })

      return reqCatalog
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    ...requirementModule.mapActions([
      'GET_STD_REQUIREMENT_MNN',
      'GET_STD_REQUIREMENT_CATALOG',
    ]),
    async init() {
      this.loading = true
      try {
        await this.GET_STD_REQUIREMENT_MNN()
        await this.GET_STD_REQUIREMENT_CATALOG()
      } finally {
        this.loading = false
        // Замыкание заголовка - чтобы не передёргивало
        // this.title = this.reqCatalog?.title
        //   ? `${this.reqCatalog?.title}:`
        //   : 'Каталог не загружен'
      }
    },
    confirmCodes(codes) {
      this.mkbTree = false
      this.$set(this, 'mkb10', codes)
      this.onChange()
    },
    async onChange() {
      let doUpd = false
      if (!ignoreOrderCompare(this.mnn, this.oldMNN)) {
        this.oldMNN = this.mnn
        doUpd = true
      }
      if (this.mkb10 !== this.oldMKB10) {
        this.oldMKB10 = this.mkb10
        doUpd = true
      }

      if (!ignoreOrderCompare(this.ageCategory, this.oldAgeCategory)) {
        this.oldAgeCategory = this.ageCategory
        doUpd = true
      }

      if (doUpd) {
        this.loading = true
        try {
          await this.GET_STD_REQUIREMENT_CATALOG({
            saved: true,
            ...(this.ageCategory && { ages: this.ageCategory }),
            ...(this.mnn && { mnn: this.mnn }),
            ...(this.mkb10 && { mkb: this.mkb10 }),
          })

          // Обновлять MNN надо при выборе стандартов из каталога
          // await this.GET_STD_REQUIREMENT_MNN()
        } finally {
          await new Promise(r => setTimeout(r, 500))
          this.loading = false
        }
      }
    },
    someSearch(item, search) {
      if (
        item.caption.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        (item?.cr_id &&
          item.cr_id
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())) ||
        (item?.std_id &&
          item.std_id.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
      )
        return item
    },
    onCalculate(std = []) {
      this.calcOptions = {
        ...(std && { std }),
        ...(this.mnn && { mnn: this.mnn }),
      }
    },
    resetCalcOptions() {
      this.calcOptions = {}
    },
  },
}
</script>

<style lang="scss" scoped>
.lvl-clinrecs {
  // display: inline-block;
  display: table-cell;
  width: 96px;
}
.lvl-standarts {
  display: table-cell;
  width: 154px;
}
.lvl-standarts-caption {
  display: table-cell;
}
::v-deep {
  .v-treeview-node__root {
    align-items: flex-start !important;
  }
  .v-treeview-node__label .lvl-standarts-caption {
    white-space: normal;
  }
}
// Cкрыть первый уровень дерева:
// ::v-deep {
//   .v-treeview-node__root > button + button.v-treeview-node__checkbox {
//     display: none !important;
//   }
//   .v-treeview-node__children button + button.v-treeview-node__checkbox {
//     display: block !important;
//   }
// }
</style>
