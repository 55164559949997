import api from '@/lib/axiosApi'

export class EApiRequirementException extends Error {
  constructor(message) {
    super(`[standarts] ${message}`)
    this.name = 'EApiRequirementException'
  }
  static check(std) {
    if (!std) {
      throw new this('Стандарт не может быть пустым')
    }
  }
  static checkId(param, msg = '') {
    if ((typeof param === 'object' && !param?.id) || !param) {
      throw new this(msg + ' (отсутствует id)')
    }
  }
  static checkArray(arr, msg = '') {
    if (arr === undefined) return

    if (!Array.isArray(arr)) {
      throw new this('Критерий фильтра должен быть массивом')
    }
    // допускается пустой массив
    if (!arr?.length) return
    if (arr.length !== arr.filter(item => item).length) {
      throw new this(msg)
    }
  }
  static checkString(str, msg = '') {
    if (str === undefined) return
    if (typeof str === 'string' && !str) {
      throw new this(msg)
    }
  }
}

export const standartsCalc = {
  getStdAgeCat: async () => {
    return await api.get(`std-req-calc/dict/age`)
  },

  getStdMNNCat: async params => {
    const { std_id } = params

    EApiRequirementException.checkArray(
      std_id,
      'Фильтр ИД стандартов содержит пустые значения'
    )

    const options = {
      ...(std_id && { std_id }),
    }
    return await api.post(`std-req-calc/dict/mnn`, options)
  },

  getStdMedicine: async params => {
    const { std_id, mnn } = params

    EApiRequirementException.checkArray(
      std_id,
      'Фильтр ИД стандартов содержит пустые значения'
    )

    EApiRequirementException.checkArray(
      mnn,
      'Фильтр MNN содержит пустые значения'
    )

    const options = {
      ...(std_id && { std_id }),
      ...(mnn && { mnn }),
    }
    return await api.post(`std-req-calc/medicine`, options)
  },

  getStdCatalog: async params => {
    const { ages, mnn, mkb } = params
    EApiRequirementException.checkString(
      ages,
      'Фильтр Возраст не может быть пустым'
    )
    EApiRequirementException.checkArray(
      mnn,
      'Фильтр MNN содержит пустые значения'
    )
    EApiRequirementException.checkString(
      mkb,
      'Фильтр Коды МКБ содержит пустое значение'
    )

    const options = {
      ...(ages && { ages }),
      ...(mnn && { mnn }),
      ...(mkb && { mkb }),
    }
    return await api.post(`std-req-calc`, options)
  },

  getStdCaclRecuirement: async params => {
    const { std, mnn } = params

    EApiRequirementException.checkArray(
      std,
      'Фильтр стандартов содержит пустые значения'
    )

    EApiRequirementException.checkArray(
      mnn,
      'Фильтр MNN содержит пустые значения'
    )

    const options = {
      ...(std && { std }),
      ...(mnn && { mnn }),
    }
    return await api.post(`std-req-calc/calc`, options)
  },
}

export default standartsCalc
