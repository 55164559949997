<template>
  <div id="user-pages">
    <PageTitle title="Расчёт потребностей ЛП по утверждённым стандартам" icon />

    <RequirementPage />
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'
import RequirementPage from '../entity/RequirementPage.vue'
import requrementModule from '../store'

export default {
  name: 'RequirementView',
  components: { RequirementPage, PageTitle },
  data() {
    return {
      error: '',
    }
  },
  beforeCreate() {
    requrementModule.register()
  },
  destroyed() {
    requrementModule.unregister()
  },
  mounted() {
    this.init()
  },
  methods: {
    ...requrementModule.mapActions(['GET_STD_REQUIREMENT_AGE']),
    async init() {
      this.error = ''
      try {
        await this.GET_STD_REQUIREMENT_AGE()
      } catch (err) {
        this.error = err
      }
    },
  },
}
</script>
